<template>
  <div>
    <!-- ESTORNO -->
    <vs-popup class="popup50" :fullscreen="false" title="Movimentação" :active.sync="localShow">
      <div class="vx-row">
        <div class="vx-col w-1/5 flex justify-center">
          <vs-icon icon="warning" size="large" color="warning"></vs-icon>
        </div>
        <div class="vx-col w-4/5">
          <vs-alert class="" :active="true" color="warning" style="height: auto">
            <span>Tem certeza que deseja {{allowDelete ? "excluir" : "estornar"}} esta movimentação?!</span>
          </vs-alert>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <label class="vs-input--label">Motivo {{ allowDelete ? "da exclusão" : "do Estorno" }}</label>
          <vs-textarea class="mb-0" v-model="chargeback.chargebackReason"
            data-vv-as="Motivo" data-vv-scope="chargeback" v-validate.initial="'required'" name="chargebackReason"/>
          <span class="text-danger text-sm" v-show="errors.has('chargeback.chargebackReason')">{{ errors.first('chargeback.chargebackReason') }}</span>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="cancel">Cancelar</vs-button>
            <vs-button class="ml-4" @click="storeChargeback" :disabled="!validateChargeback || disableSave" color="danger">{{allowDelete ? "Excluir" :"Estornar"}} Movimentação</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
// Store Module
import moduleGeneralCash from '@/store/general-cash/moduleGeneralCash.js'

export default {
  props: {
    show: {
      type: Boolean, default: false
    }
  },

  computed: {
    validateChargeback () {
      return !this.errors.any('chargeback')
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    transaction () {
      const data = this.$store.state.generalCash ? this.$store.state.generalCash.generalTransaction : null
      return data
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
        this.resetModal() //reseta o form ao fechar a modal
      }
    }
  },

  created () {
    if (!moduleGeneralCash.isRegistered) {
      this.$store.registerModule('generalCash', moduleGeneralCash)
      moduleGeneralCash.isRegistered = true
    }
  },

  data () {
    return {
      localShow: false,
      disableSave: false,
      allowDelete: false,
      chargeback: {
        chargebackReason: ''
      }
    }
  },

  methods: {
    cancel () {
      this.$emit('cancel')
    },
    resetModal () {
      this.chargeback.chargebackReason = null
    },
    storeChargeback () {
      // Loading
      this.$vs.loading()
      this.disableSave = true
      setTimeout(() => { this.disableSave = false }, 2000) //TRAVA O BOTÃO SALVAR

      this.$store.dispatch('generalCash/chargeback', {id: this.transaction.transaction_id, chargebackReason: this.chargeback.chargebackReason, delete: this.allowDelete, transactionCashFlow: this.transaction})
        .then(() => {
          this.$emit('saved')
          this.localShow = false

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Movimentação Estornada.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          console.log(error)
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}

</style>
